<template>
  <div
    class="category-filter"
    :class="{
      'category-filter--menu': isFilterMenu || isCategoriesMenu,
      'category-filter--categories-menu': isCategoriesMenu,
    }"
  >
    <FilterItemComponent v-if="!isFilterMenu" title="Сортировка" :opened="true">
      <SelectComponent
        v-model="form.sortBy"
        title="Выбрать"
        label-name="title"
        :options="sortTypes"
        placeholder="Выбрать"
      />
    </FilterItemComponent>
    <FilterItemComponent v-if="$route.meta.filters" title="Категория" :opened="true">
      <SelectComponent
        v-model="form.category_link"
        title="Выбрать"
        label-name="title"
        :options="categories"
        id-name="id"
        placeholder="Выбрать"
      />
    </FilterItemComponent>
    <FilterItemComponent title="Фильтры" :opened="true">
      <ul class="category-filter__categories">
        <!--        <li>-->
        <!--          <div class="category-filter__block-title">-->
        <!--            <span>Акции</span>-->
        <!--            <button @click="stock = !stock" class="btn">-->
        <!--              <RemixIconComponent category="system" name="arrow-right-s-line" />-->
        <!--            </button>-->
        <!--          </div>-->
        <!--          <div v-if="stock" class="category-filter__block-items">-->
        <!--            <CheckboxComponent-->
        <!--              v-for="(item, i) in stocks"-->
        <!--              :key="i"-->
        <!--              :checked="isStockChecked(item.id)"-->
        <!--              @change="toggleValue(item.id)"-->
        <!--            >-->
        <!--              <span>{{ item.title }}</span>-->
        <!--            </CheckboxComponent>-->
        <!--          </div>-->
        <!--        </li>-->
        <li class="category-filter__block" :class="{ 'category-filter__block--open': price }">
          <button @click="price = !price" class="category-filter__block-title">
            <span>Цена</span>
            <RemixIconComponent category="system" :name="price ? 'arrow-up-s-line' : 'arrow-down-s-line'" />
          </button>
          <div v-if="price" class="category-filter__block-items">
            <div class="category-filter__price">
              <CategoryRangeSlider @change="priceChange" :min-value="minValue" :max-value="maxValue" />
            </div>
          </div>
        </li>
        <li
          v-if="countries && countries.length"
          class="category-filter__block"
          :class="{ 'category-filter__block--open': country }"
        >
          <button @click="country = !country" class="category-filter__block-title">
            <span>Страна</span>
            <RemixIconComponent category="system" :name="country ? 'arrow-up-s-line' : 'arrow-down-s-line'" />
          </button>
          <div v-if="country" class="category-filter__block-items">
            <CheckboxComponent
              v-for="(item, i) in countries"
              :key="i"
              :checked="isChecked(item.id, 'countries')"
              @change="toggleValue(item.id, 'countries')"
            >
              <span>{{ item.title }}</span>
            </CheckboxComponent>
          </div>
        </li>
        <li
          v-if="brands && brands.length"
          class="category-filter__block"
          :class="{ 'category-filter__block--open': brand }"
        >
          <button @click="brand = !brand" class="category-filter__block-title">
            <span>Бренд</span>
            <RemixIconComponent category="system" :name="brand ? 'arrow-up-s-line' : 'arrow-down-s-line'" />
          </button>
          <div v-if="brand" class="category-filter__block-items">
            <CheckboxComponent
              v-for="(item, i) in brands"
              :key="i"
              :checked="isChecked(item.id, 'brands')"
              @change="toggleValue(item.id, 'brands')"
            >
              <span>{{ item.title }}</span>
            </CheckboxComponent>
          </div>
        </li>
      </ul>
    </FilterItemComponent>
  </div>
</template>

<script>
import FilterItemComponent from "./FilterItemComponent.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import CategoryRangeSlider from "./CategoryRangeSlider.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";

function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "CategoryFiltersComponent",
  props: {
    loading: Boolean,
    isFilterMenu: Boolean,
    isCategoriesMenu: Boolean,
  },
  data() {
    return {
      isBrandPage: false,
      active: undefined,
      activeChild: undefined,
      inputDebounceTimeout: null,
      stock: true,
      price: true,
      country: true,
      brand: false,
      category: false,
      minValue: 0,
      maxValue: 20000,
      brandsSearch: "",
      params: {},
      form: {
        stock: [],
        countries: [],
        brands: [],
        category_link: {},
        price_from: 0,
        price_to: 20000,
        sortBy: { orderColumn: "title", orderBy: "ASC" },
      },
      defaultForm: {
        stock: [],
        countries: [],
        brands: [],
        category_link: undefined,
        price_from: 0,
        price_to: 20000,
        sortBy: { orderColumn: "title", orderBy: "ASC" },
      },
      sortTypes: [
        { title: "Сначала недорогие", value: { orderColumn: "price", orderBy: "ASC" } },
        { title: "Сначала дорогие", value: { orderColumn: "price", orderBy: "DESC" } },
        { title: "Сначала популярные", value: { orderColumn: "popularity", orderBy: "ASC" } },
      ],
    };
  },
  watch: {
    form: {
      handler() {
        if (this.inputDebounceTimeout) {
          clearTimeout(this.inputDebounceTimeout);
        }
        this.inputDebounceTimeout = setTimeout(() => {
          this.setUrlParams();
          this.setVariables();
        }, 500);
      },
      deep: true,
    },
  },
  created() {
    this.params = this.parseParams(this.$route.query);
    this.setForm();
  },
  mounted() {
    // this.isBrandPage = new URLSearchParams(window.location.search).get("brands_item_id");
  },
  computed: {
    // stocks() {
    //   return this.$store.state.category_open_page.brands;
    // },
    countries() {
      return this.$store.state.category_open_page.countries;
    },
    brands() {
      return this.$store.state.category_open_page.brands;
    },
    categories() {
      return this.$store.getters.parent_categories;
    },
  },
  methods: {
    parseParams,
    setForm() {
      if (this.params) {
        Object.keys(this.form).forEach((key) => {
          if (key === "category_link") {
            // суета
          } else if (key === "sortBy") {
            this.form[key] = this.sortTypes.find(
              (item) => JSON.stringify(item.value) === JSON.stringify(this.params[key])
            );
          } else if (this.params[key]) {
            this.form[key] = this.params[key];
          }
        });
      }
    },
    setVariables() {
      this.$store.state.variables = JSON.parse(JSON.stringify(this.form));
    },
    setUrlParams() {
      const urlParams = new URLSearchParams();
      Object.keys(this.form).forEach((key) => {
        if (key === "category_link") {
          if (this.form[key].link) urlParams.set(key, this.form[key].link);
        } else if (Array.isArray(this.form[key])) {
          if (this.form[key].length) {
            urlParams.set(key, JSON.stringify(this.form[key]));
          }
        } else if (key === "sortBy") {
          urlParams.set(
            key,
            JSON.stringify(this.form[key]?.value ? this.form[key].value : this.defaultForm[key])
          );
        } else if (
          this.form[key] &&
          JSON.stringify(this.form[key] !== JSON.stringify(this.defaultForm[key]))
        ) {
          urlParams.set(key, this.form[key]);
        }
      });
      history.pushState({}, null, location.origin + location.pathname + "?" + urlParams.toString());
    },
    priceChange(range) {
      this.form.price_from = range.min;
      this.form.price_to = range.max;
    },
    isChecked(id, field) {
      return this.form[field] && this.form[field].includes(id);
    },
    toggleValue(id, field) {
      if (this.form[field].includes(id)) {
        this.form[field].splice(
          this.form[field].findIndex((item) => item === id),
          1
        );
        return;
      }
      this.form[field].push(id);
    },
  },
  components: {
    SelectComponent,
    RemixIconComponent,
    CategoryRangeSlider,
    CheckboxComponent,
    FilterItemComponent,
  },
};
</script>

<style lang="stylus">
.category-filter {
  display grid
  align-items start
  grid-gap 15px
  background: var(--white);
  height: fit-content;
  position sticky
  top var(--header-main-height)
  &:not(&--menu) {
    +below(990px) {
      display none
    }
  }

  &--menu {
    top 0

    .filter-item {
      border none
      padding 0
    }

    .filter-item__header {
      font-size 1em
    }

    .check {
      font-size 0.875em
    }
  }

  &--categories-menu {
    .filter-item {
      border none
    }

    .filter-item__header {
      display none
    }

    .category-filter__categories {
      max-height none

      > li:not(:last-child) {
        border none
      }
    }
  }

  &__header {
    border-bottom: 1px solid var(--blue-500);
    display grid
    grid-gap 8px
    padding-bottom 16px
  }

  &__header-top {
    display flex
    align-items center
    justify-content space-between
  }

  &__title {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__body {
    display flex
    flex-direction column
    grid-gap 16px

    &-container {
      display grid
      grid-gap 30px
    }
  }

  &__categories {
    display grid
    max-height calc(100vh - 320px)
    overflow-y auto

    > li {
      display grid

      &:not(:last-child) {}
    }
  }

  &__category {
    padding 10px 40px 10px 20px
    font-size: 0.875em
    line-height: 145%;
    gap 8px
    display flex
    align-items center
    justify-content space-between

    //&:hover {
    //  background var(--gray)
    //}

    &--active {
      color: var(--brown-light);

      .icon svg path {
        stroke: var(--brown-light);
      }
    }

    .icon {
      width 12px
      height 12px
    }

    > .btn {
      height 40px
      width 40px
      position absolute
      right 0
      top 0
      bottom 0
      border-radius 0

      &:hover {
        background var(--gray)
      }

      &.active {
        .icon {
          transition var(--transition)
          transform rotate(90deg)
        }
      }
    }
  }

  &__price {
    padding 0 20px
    display grid
    grid-gap 20px
  }

  &__brands {
    padding 20px
    padding-right 10px

    .input {
      width 100%
      height 40px
      margin-bottom: 10px
    }

    &-list {
      display grid
      max-height 260px
      overflow-y auto
    }

    .check {
      padding 5px 0
    }
  }

  &__checkboxes {
    display grid
    max-height 290px
    overflow-y auto

    .check {
      color var(--body-color-muted)
      padding 8px 0
      transition var(--transition)

      &:hover {
        color var(--brown)

        .check__state {
          border-color var(--brown)
        }
      }
    }
  }

  &__colors {
    display flex
    gap 12px
    flex-wrap wrap
    align-items flex-start

    li {
      display inline-flex
    }

    label {
      cursor pointer
      width 24px
      height 24px
      flex-shrink 0
      border-radius 100%
      outline 2px solid transparent
      transition outline-color var(--transition)

      &.active {
        outline-color var(--brown)
      }
    }
  }
  &__block {
    &--open &-title {
      border-bottom: 1px solid var(--border-color-2);
    }

    &-items {
      transition var(--transition)
      padding 16px 0
      display flex
      flex-direction column
      gap 16px

      span {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 140%;
        color: var(--gray-900);
        opacity: 0.8;
        transition var(--transition)

        &:hover {
          color var(--main)
        }
      }
    }
    &-title {
      display flex
      justify-content space-between
      padding 12px 5px 12px 0
      align-items center
      font-weight: 500;
      font-size: 1em;
      line-height: 120%;
      color: var(--gray-900);
      background none
      border none
      border-top: 1px solid var(--border-color-2);
      cursor pointer
      transition color var(--transition)

      .icon {
        width 16px
        height 16px

        svg path {
          transition var(--transition)
        }
      }

      &:hover {
        //background var(--green-50)
        color var(--main)

        .icon svg path {
          fill var(--main)
        }
      }
    }
  }
}
</style>
